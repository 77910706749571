import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PartenairesHome() {
  const partenaires = [
    {
      nom: "Le Temps des Travaux",
      logo: "/img/logo_temps.webp", // Remplacez par le chemin réel du logo
      url: "https://www.letempsdestravaux.info/",
    },
    {
      nom: "Twinkle",
      logo: "/img/logo_twinkle.webp", // Remplacez par le chemin réel du logo
      url: "https://www.facebook.com/people/Twinkle-Architecte-Consultante/100064790177981/",
    },
    {
      nom: "Ginger",
      logo: "img/logo_ginger.webp", // Remplacez par le chemin réel du logo
      url: "https://www.gingerarchitectures.com/",
    },
    {
      nom: "Les Demeures du Bassin",
      logo: "/img/logo_demeures.webp", // Remplacez par le chemin réel du logo
      url: "https://www.lesdemeuresdubassin.fr/",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="partenaires-home"
      style={{
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "75px",
        paddingTop: "15px",
      }}
    >
      <Slider {...settings}>
        {partenaires.map((partenaire, index) => (
          <div
            key={index}
            className="px-4"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={partenaire.logo}
              alt={partenaire.nom}
              title={partenaire.nom} // Nom de l'entreprise au survol
              style={{ width: "auto", height: "75px", margin: "0 auto" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default PartenairesHome;

import React from "react";

const AsidePrestations = ({ onCategoryChange }) => {
  const categories = [
    "Toutes les prestations",
    "Etat des lieux",
    "Avant Projet construction 0/149 m²",
    "Permis de construire et Déclaration préalable",
    "Recours Architecte",
    "Rendus 3D",
    "Plan d'aménagement ou réaménagement Intérieur",

    // Ajoutez d'autres catégories ici
  ];

  return (
    <aside className="space-y-8 bg-gray-300 p-6 rounded-lg shadow-lg">
      <div className="mb-8">
        <h3 className="font-bold text-xl mb-4 text-gray-800">
          Types de Prestations
        </h3>
        <ul className="space-y-2">
          {categories.map((categorie, index) => (
            <li
              key={index}
              onClick={() => onCategoryChange(categorie)}
              className="cursor-pointer hover:text-blue-600 transition-colors duration-200 ease-in-out border-b border-gray-400 pb-2 mb-2"
            >
              {categorie}
            </li>
          ))}
        </ul>
      </div>
      
    </aside>
  );
};

export default AsidePrestations;

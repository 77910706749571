import React, { useState, useEffect } from "react";
import axios from "axios";

function ContactsTab() {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get(
        "https://api.dr-concept.fr/getAllContacts"
      );
      setContacts(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des contacts:", error);
    }
  };

  const deleteContact = async (id) => {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce contact ?"
    );
    if (isConfirmed) {
      try {
        await axios.delete(`https://api.dr-concept.fr/getAllContacts/${id}`);
        fetchContacts(); // Recharger les contacts après la suppression
      } catch (error) {
        console.error("Erreur lors de la suppression du contact:", error);
      }
    }
  };

  // Formatteur de date pour DD/MM/YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2); // Ajoute un zéro devant et prend les deux derniers chiffres pour le jour
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ajoute un zéro devant et prend les deux derniers chiffres pour le mois
    const year = date.getFullYear(); // L'année en 4 chiffres
    return `${day}/${month}/${year}`; // Format DD/MM/YYYY
  };

  return (
    <div>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Date</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Téléphone</th>
            <th>Email</th>
            <th>Demande</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact.id}>
              <td>{formatDate(contact.createdAt)}</td>
              <td>{contact.nom}</td>
              <td>{contact.prenom}</td>
              <td>{contact.telephone}</td>
              <td>{contact.mail}</td>
              <td>{contact.demande}</td>
              <td>
                <small
                  className="tag is-danger cursor-pointer"
                  onClick={() => deleteContact(contact.id)}
                >
                  Supprimer
                </small>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ContactsTab;

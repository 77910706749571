import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import Prestations from "./pages/Prestations/Prestations";
import Footer from "./components/Footer";
import Realisations from "./pages/Realisations/Realisations";
import RealisationDetail from "./pages/Realisations/RealisationDetail";
import ContactPage from "./pages/ContactPage/ContactPage";
import AdminPage from "./pages/AdminPage/AdminPage"; 
import AdminSettings from "./pages/AdminPage/AdminSettings";

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Logo /> */}
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/prestations" element={<Prestations />} />
          <Route path="/realisations" element={<Realisations />} />
          <Route path="/realisations/:id" element={<RealisationDetail />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/settings" element={<AdminSettings />} />
          {/* Ajoute d'autres routes au besoin */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

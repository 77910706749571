import React, { useState, useEffect } from "react";
import axios from "axios";

function HeaderCarousel() {
  const [carouselData, setCarouselData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    // Fonction pour charger les données du carrousel depuis l'API
    const fetchCarouselData = async () => {
      try {
        const response = await axios.get(
          "https://api.dr-concept.fr/getAllCarousels"
        );
        setCarouselData(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données du carrousel:",
          error
        );
      }
    };

    fetchCarouselData();

    const timer = setTimeout(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselData.length);
    }, 5000); // Change de slide toutes les 5 secondes

    return () => clearTimeout(timer);
  }, [currentSlide, carouselData.length]); // Ajoute carouselData.length comme dépendance pour recalculer après le chargement des données

  return (
    <div
      className="relative w-full overflow-hidden flex justify-center items-center"
      style={{ minHeight: "481px", maxHeight: "481px" }}
    >
      {carouselData.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
            index === currentSlide ? "opacity-100" : "opacity-0"
          } flex justify-center items-center w-full`}
          style={{ minHeight: "481px" }}
        >
          <img
            src={slide.photo_url}
            alt={slide.nom}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "auto",
              height: "auto",
              objectFit: "contain",
            }}
            onClick={() => {
              window.location.href = "/prestations";
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default HeaderCarousel;

// components/CarouselTab.js
import React, { useEffect, useState } from "react";
import axios from "axios";

const CarouselTab = () => {
  const [items, setItems] = useState([]);
  const [isModalActive, setIsModalActive] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    visible: true,
    photo_url: "",
    nom: "",
    description: "",
    ordre: 0,
  }); // Objet vide pour la création
  const [modalAction, setModalAction] = useState("create"); // 'create' ou 'edit'

  useEffect(() => {
    fetchCarousels();
  }, []);

  const fetchCarousels = async () => {
    try {
      const response = await axios.get(
        "https://api.dr-concept.fr/getAllCarousels"
      );
      setItems(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des éléments du carrousel: ",
        error
      );
    }
  };

  const handleModalToggle = () => {
    setIsModalActive(!isModalActive);
  };

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet élément ?"
    );
    if (isConfirmed) {
      try {
        await axios.delete(`https://api.dr-concept.fr/deleteCarousel/${id}`);
        fetchCarousels();
      } catch (error) {
        console.error("Erreur lors de la suppression de l'élément: ", error);
      }
    }
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalAction("edit");
    setIsModalActive(true);
  };

  const handleAdd = () => {
    setCurrentItem({
      visible: true,
      photo_url: "",
      nom: "",
      description: "",
      ordre: 0,
    });
    setModalAction("create");
    setIsModalActive(true);
  };

  const handleSubmit = async () => {
    const url =
      modalAction === "create"
        ? "https://api.dr-concept.fr/createCarousel"
        : `https://api.dr-concept.fr/${currentItem.id}`;

    try {
      const method = modalAction === "create" ? axios.post : axios.put;
      await method(url, currentItem);
      setIsModalActive(false);
      fetchCarousels();
    } catch (error) {
      console.error(
        "Erreur lors de la création ou de la mise à jour : ",
        error
      );
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCurrentItem({
      ...currentItem,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <section className="section">
      <button className="button is-primary mb-2" onClick={handleAdd}>
        Ajouter une image
      </button>
      <table className="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th>Ordre</th>
            <th>Nom</th>
            <th>Description</th>
            <th>Visible</th>
            <th>Photo URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.ordre}</td>
              <td>{item.nom}</td>
              <td>{item.description}</td>
              <td>{item.visible ? "Oui" : "Non"}</td>
              <td>
                <a
                  href={item.photo_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Voir Image
                </a>
              </td>
              <td>
                <button
                  className="tag is-small is-info mr-2"
                  onClick={() => handleEdit(item)}
                >
                  Éditer
                </button>
                <button
                  className="tag is-small is-danger"
                  onClick={() => handleDelete(item.id)}
                >
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {isModalActive && (
        <div className="modal is-active">
          <div className="modal-background" onClick={handleModalToggle}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                {modalAction === "create"
                  ? "Ajouter un nouvel élément"
                  : "Éditer l'élément"}
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleModalToggle}
              ></button>
            </header>
            <section className="modal-card-body">
              {/* Form Fields */}
              <div className="field">
                <label className="label">Nom</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="nom"
                    value={currentItem.nom}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="description"
                    value={currentItem.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Photo URL</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="photo_url"
                    value={currentItem.photo_url}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Ordre</label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name="ordre"
                    value={currentItem.ordre}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="visible"
                    checked={currentItem.visible}
                    onChange={handleChange}
                  />
                  Visible
                </label>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" onClick={handleSubmit}>
                Sauvegarder
              </button>
              <button className="button" onClick={handleModalToggle}>
                Annuler
              </button>
            </footer>
          </div>
        </div>
      )}
    </section>
  );
};

export default CarouselTab;

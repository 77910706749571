import React from "react";
import { FaFacebook, FaLinkedin, FaInstagramSquare } from "react-icons/fa"; // Assure-toi d'avoir les icônes appropriées

const Footer = () => {
  return (
    <footer className="divide-y divide-gray-700">
      <div className="bg-gray-800 text-white py-6">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between">
          <div className="flex justify-between items-center">
            <span className="text-white">Suivez-moi sur :</span>
            <div className="flex">
              <a
                href="https://www.instagram.com/_dr_concept?igsh=MWV3N3U2YjY2emczYQ=="
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-200 hover:text-white ml-4"
              >
                <FaInstagramSquare size="1.5em" />
              </a>
              <a
                href="https://www.linkedin.com/in/dr-concept-03939689/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-200 hover:text-white ml-4"
              >
                <FaLinkedin size="1.5em" />
              </a>
            </div>
          </div>
          <div className="text-center my-4 md:my-0">
            {/* Assure-toi que le logo est bien le tien */}
            <img
              src="/img/logo_drconcept33_black.png"
              alt="Dr. Concept 33"
              className="h-64 mx-auto"
            />
          </div>
          <div className="text-gray-400">
            <span className="text-white">Contactez-moi :</span>
            <p>Dr. Concept 33</p>
            <p>Téléphone : 06.66.15.07.48</p>

            <a href="/contact" className="text-gray-200 hover:text-white">
              Formulaire de contact
            </a>
          </div>
        </div>
      </div>
      <div className="bg-black text-gray-400 text-center py-4">
        © 2024 Dr. Concept 33. Tous droits réservés
      </div>
    </footer>
  );
};

export default Footer;

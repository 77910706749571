import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "bulma/css/bulma.min.css";
import "./Realisations.css";

const RealisationDetail = () => {
  let { id } = useParams();
  id = parseInt(id, 10);

  const [realisation, setRealisation] = useState(null);
  const [imagePrincipale, setImagePrincipale] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const [lienPlan, setLienPlan] = useState("");

  useEffect(() => {
    const fetchRealisation = async () => {
      try {
        const response = await axios.get(
          `https://api.dr-concept.fr/getOneRealisation/${id}`
        );
        if (response.data) {
          setRealisation(response.data);
          setImagePrincipale(response.data.photo_url);

          let updatedThumbnails = [
            response.data.lien_image2, // Assurez-vous que cette variable contient l'URL de l'image
          ].filter(Boolean); // Filtre pour supprimer les valeurs falsy

          // Vérifie si lien_plan est un PDF
          if (
            response.data.lien_plan &&
            response.data.lien_plan.includes("drive.google")
          ) {
            // Transforme le lien Google Drive pour le visionnage direct
            const driveRegex =
              /https:\/\/drive\.google\.com\/file\/d\/(.+)\/view\?usp=sharing/;
            const match = response.data.lien_plan.match(driveRegex);
            if (match) {
              const fileID = match[1];
              // Format pour l'ouverture dans la visionneuse Google Drive
              const viewLink = `https://drive.google.com/file/d/${fileID}/view`;
              setLienPlan(viewLink);
              // Ajoute une image par défaut pour le PDF dans les thumbnails
              updatedThumbnails.push("/img/Real_plan_pdf.webp");
            }
          } else {
            // Si lien_plan est une image normale, ajoutez-la à thumbnails
            if (response.data.lien_plan) {
              updatedThumbnails.push(response.data.lien_plan);
            }
          }

          setThumbnails(updatedThumbnails);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la réalisation:",
          error
        );
      }
    };

    if (id) {
      fetchRealisation();
    }
  }, [id]);

  const handleThumbnailClick = (newImagePrincipale) => {
    // Sauvegarder l'ancienne image principale
    const oldImagePrincipale = imagePrincipale;

    // Mettre à jour l'image principale avec celle cliquée
    setImagePrincipale(newImagePrincipale);

    // Mettre à jour les miniatures :
    // - Inclure l'ancienne image principale dans les miniatures
    // - Exclure l'image qui vient de devenir principale
    const updatedThumbnails = thumbnails.map((img) => {
      if (img === newImagePrincipale) {
        return oldImagePrincipale;
      }
      return img;
    });

    setThumbnails(updatedThumbnails);
  };

  if (!realisation) {
    return <div>Projet non trouvé</div>;
  }

  return (
    <div className="container realisation-detail-container">
      <div className="buttons">
        <Link to="/realisations" className="button is-info is-light">
          ← Retour aux réalisations
        </Link>
      </div>
      <h1 className="title">{realisation.title}</h1>
      <h2 className="subtitle">{realisation.category}</h2>
      <div className="content">
        <p>{realisation.description}</p>
      </div>
      <div className="images">
        <figure className="image is-3by2">
          <img src={imagePrincipale} alt="Vue principale" />
        </figure>
        <div className="thumbnails">
          {thumbnails.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(url)}
              style={{
                cursor: "pointer",
                margin: "5px",
                width: "100px",
                height: "auto",
              }}
            />
          ))}
        </div>
      </div>
      {lienPlan && (
        <div className="pdf-view-button">
          <a
            href={lienPlan}
            target="_blank"
            rel="noopener noreferrer"
            className="button is-link mb-3"
          >
            Visualiser les plans PDF
          </a>
        </div>
      )}
    </div>
  );
};

export default RealisationDetail;

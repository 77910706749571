import React, { useEffect, useState } from "react";
import axios from "axios";

const RealisationsTab = () => {
  const [realisations, setRealisations] = useState([]);
  const [isModalActive, setIsModalActive] = useState(false);
  const [currentRealisation, setCurrentRealisation] = useState({
    photo_url: "",
    lien_plan: "",
    lien_image2: "",
    title: "",
    description: "",
    category: "",
    visible: true,
    ordre: 0,
  });
  const [modalAction, setModalAction] = useState("create");

  useEffect(() => {
    fetchRealisations();
  }, []);

  const fetchRealisations = async () => {
    try {
      const response = await axios.get(
        "https://api.dr-concept.fr/getAllRealisations"
      );
      setRealisations(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des réalisations:", error);
    }
  };

  const handleModalToggle = () => setIsModalActive(!isModalActive);

  const handleDelete = async (id) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cette réalisation ?")
    ) {
      try {
        await axios.delete(`https://api.dr-concept.fr/deleteRealisation/${id}`);
        fetchRealisations();
      } catch (error) {
        console.error(
          "Erreur lors de la suppression de la réalisation:",
          error
        );
      }
    }
  };

  const handleEdit = (realisation) => {
    setCurrentRealisation(realisation);
    setModalAction("edit");
    setIsModalActive(true);
  };

  const handleAdd = () => {
    setCurrentRealisation({
      photo_url: "",
      lien_plan: "",
      lien_image2: "",
      title: "",
      description: "",
      category: "",
      visible: true,
      ordre: 0,
    });
    setModalAction("create");
    setIsModalActive(true);
  };

  const handleSubmit = async () => {
    const method = modalAction === "create" ? axios.post : axios.put;
    const url = `https://api.dr-concept.fr/${
      modalAction === "create"
        ? "createRealisation"
        : "updateRealisation/" + currentRealisation.id
    }`;

    try {
      await method(url, currentRealisation);
      setIsModalActive(false);
      fetchRealisations();
    } catch (error) {
      console.error(
        "Erreur lors de la création/mise à jour de la réalisation:",
        error
      );
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCurrentRealisation({
      ...currentRealisation,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <section className="section">
      <button className="button is-primary mb-2" onClick={handleAdd}>
        Ajouter une réalisation
      </button>
      <table className="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th>Titre</th>
            <th>Description</th>
            <th>Catégorie</th>
            <th>Visible</th>
            <th>Ordre</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {realisations.map((realisation) => (
            <tr key={realisation.id}>
              <td>{realisation.title}</td>
              <td>{realisation.description}</td>
              <td>{realisation.category}</td>
              <td>{realisation.visible ? "Oui" : "Non"}</td>
              <td>{realisation.ordre}</td>
              <td>
                <button
                  className="tag is-small is-info mr-2"
                  onClick={() => handleEdit(realisation)}
                >
                  Éditer
                </button>
                <button
                  className="tag is-small is-danger"
                  onClick={() => handleDelete(realisation.id)}
                >
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal pour ajouter ou éditer une réalisation */}
      {isModalActive && (
        <div className="modal is-active">
          <div className="modal-background" onClick={handleModalToggle}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                {modalAction === "create"
                  ? "Ajouter une nouvelle réalisation"
                  : "Éditer la réalisation"}
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleModalToggle}
              ></button>
            </header>
            <section className="modal-card-body">
              <section className="modal-card-body">
                {/* Modal pour ajouter ou éditer une réalisation */}
                {isModalActive && (
                  <div className="modal is-active">
                    <div
                      className="modal-background"
                      onClick={handleModalToggle}
                    ></div>
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">
                          {modalAction === "create"
                            ? "Ajouter une nouvelle réalisation"
                            : "Éditer la réalisation"}
                        </p>
                        <button
                          className="delete"
                          aria-label="close"
                          onClick={handleModalToggle}
                        ></button>
                      </header>
                      <section className="modal-card-body">
                        {/* Formulaire pour la création/édition */}
                        <div className="field">
                          <label className="label">Titre</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="title"
                              value={currentRealisation.title}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Description</label>
                          <div className="control">
                            <textarea
                              className="textarea"
                              name="description"
                              value={currentRealisation.description}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Catégorie</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="category"
                              value={currentRealisation.category}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">
                            URL de l'image principale
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="photo_url"
                              value={currentRealisation.photo_url}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">
                            Lien du plan (optionnel)
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="lien_plan"
                              value={currentRealisation.lien_plan}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">
                            Lien de l'image secondaire (optionnel)
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="lien_image2"
                              value={currentRealisation.lien_image2}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="visible"
                              checked={currentRealisation.visible}
                              onChange={handleChange}
                            />
                            Visible
                          </label>
                        </div>
                        <div className="field">
                          <label className="label">Ordre</label>
                          <div className="control">
                            <input
                              className="input"
                              type="number"
                              name="ordre"
                              value={currentRealisation.ordre}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </section>
                      <footer className="modal-card-foot">
                        <button
                          className="button is-success"
                          onClick={handleSubmit}
                        >
                          Sauvegarder
                        </button>
                        <button className="button" onClick={handleModalToggle}>
                          Annuler
                        </button>
                      </footer>
                    </div>
                  </div>
                )}{" "}
              </section>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" onClick={handleSubmit}>
                Sauvegarder
              </button>
              <button className="button" onClick={handleModalToggle}>
                Annuler
              </button>
            </footer>
          </div>
        </div>
      )}
    </section>
  );
};

export default RealisationsTab;

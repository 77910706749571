/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  FaInstagram,
  FaLinkedinIn,
  FaMoon,
  FaSun,
  FaPhone,
  FaEnvelope,
  FaBars,
  FaCog,
} from "react-icons/fa";
import "./Navbar.css";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Gestion du mode sombre
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  // Ajout de la fonction pour fermer le menu hamburger
  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-100 dark:bg-gray-800 fixed w-full z-50 top-0 shadow-md">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center py-3">
          {/* Logo section */}
          <div>
            <NavLink
              to="/"
              className="flex items-center text-gray-700 dark:text-gray-200 hover:text-gray-900"
            >
              <img
                src="/img/avatar_logo.png"
                alt="Logo"
                className="h-12 w-12 rounded-full mr-3"
              />
              <div className="flex flex-col md:flex-row items-start md:items-center">
                <span className="font-bold mr-2">Dr. Concept 33 |</span>
                <span className="italic text-sm text-gray-500 font-thin">
                  Atelier de dessin en Architecture
                </span>
              </div>
            </NavLink>
          </div>

          {/* Menu section for larger screens */}
          <div className="hidden md:flex items-center space-x-4">
            <NavLink
              to="/"
              className="py-2 px-3 text-gray-700 dark:text-gray-200 hover:text-gray-900"
            >
              Accueil
            </NavLink>
            <NavLink
              to="/prestations"
              className="py-2 px-3 text-gray-700 dark:text-gray-200 hover:text-gray-900"
            >
              Prestations
            </NavLink>
            <NavLink
              to="/realisations"
              className="py-2 px-3 text-gray-700 dark:text-gray-200 hover:text-gray-900"
            >
              Réalisations
            </NavLink>
            <NavLink
              to="/contact"
              className="py-2 px-3 text-gray-700 dark:text-gray-200 hover:text-gray-900"
            >
              Contact
            </NavLink>
            <a
              href="https://www.instagram.com/_dr_concept?igshid=MWV3N3U2YjY2emczYQ=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-gray-700 dark:text-gray-200 hover:text-gray-900 cursor-pointer" />
            </a>
            <a
              href="https://www.linkedin.com/in/dr-concept-03939689/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn className="text-gray-700 dark:text-gray-200 hover:text-gray-900 cursor-pointer" />
            </a>
            <div className="flex items-center space-x-2">
              <NavLink
                to="tel:+33666150748"
                className="text-gray-700 dark:text-gray-200 hover:text-gray-900 cursor-pointer"
              >
                <FaPhone className="text-gray-700 dark:text-gray-200 hover:text-gray-900 cursor-pointer" />
              </NavLink>
              <NavLink
                to="/contact"
                className="text-gray-700 dark:text-gray-200 hover:text-gray-900 cursor-pointer"
              >
                <FaEnvelope className="text-gray-700 dark:text-gray-200 hover:text-gray-900 cursor-pointer" />
              </NavLink>
              <NavLink
                to="/admin"
                className="py-2 text-gray-700 dark:text-gray-200 hover:text-gray-900"
              >
                <FaCog className="cursor-pointer" />
              </NavLink>
            </div>
            <button
              onClick={() => setIsDarkMode(!isDarkMode)}
              className="bg-transparent border-none"
            >
              {isDarkMode ? <FaSun /> : <FaMoon />}
            </button>
          </div>

          {/* Hamburger menu for smaller screens */}
          <div className="md:hidden flex items-center">
            <button onClick={() => setIsOpen(!isOpen)}>
              <FaBars className="text-gray-700 dark:text-gray-200 hover:text-gray-900" />
            </button>
          </div>

          {/* Mobile menu */}
          <div
            className={`${
              isOpen ? "flex" : "hidden"
            } md:hidden flex-col space-y-2 mt-2`}
          >
            <NavLink
              to="/"
              className="block py-2 px-4 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 mb-0"
              onClick={handleCloseMenu} // Fermer le menu en cliquant sur le lien
            >
              Accueil
            </NavLink>
            {/* Répétez le même processus pour les autres liens du menu mobile */}
            <NavLink
              to="/prestations"
              className="block py-2 px-4 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
              onClick={handleCloseMenu}
            >
              Prestations
            </NavLink>
            <NavLink
              to="/realisations"
              className="block py-2 px-4 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
              onClick={handleCloseMenu}
            >
              Réalisations
            </NavLink>
            <NavLink
              to="/contact"
              className="block py-2 px-4 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 mb-1"
              onClick={handleCloseMenu}
            >
              Contact
            </NavLink>
            {/* Icons */}
            <div className="flex space-x-4 justify-center icon-container">
              {/* ajout des liens vers réseaux sociaux */}

              <NavLink
                to="https://www.instagram.com/_dr_concept?igshid=MWV3N3U2YjY2emczYQ=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </NavLink>
              <NavLink
                to="https://www.linkedin.com/in/dr-concept-03939689/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </NavLink>
              {/* ajout des liens vers téléphone et mail */}
              <NavLink to="tel:+33666150748" onClick={handleCloseMenu}>
                <FaPhone />
              </NavLink>
              <NavLink to="/contact" onClick={handleCloseMenu}>
                <FaEnvelope />
              </NavLink>
              <NavLink
                to="/admin"
                onClick={handleCloseMenu}
                className="icon-button" // Utilisez `icon-button` pour la couleur
              >
                <FaCog />
              </NavLink>
              <button
                onClick={() => setIsDarkMode(!isDarkMode)}
                className="bg-transparent border-none icon-button" // Utilisez `icon-button` pour la couleur
              >
                {isDarkMode ? <FaSun /> : <FaMoon />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

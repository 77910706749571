import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "bulma/css/bulma.min.css";
import "./Realisations.css";

const Realisations = () => {
  const [realisations, setRealisations] = useState([]);

  useEffect(() => {
    const fetchRealisations = async () => {
      try {
        const response = await axios.get(
          "https://api.dr-concept.fr/getAllRealisations"
        );
        setRealisations(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des réalisations:",
          error
        );
      }
    };

    fetchRealisations();
  }, []);

  return (
    <div className="container realisations-container">
      <div className="columns is-multiline">
        {realisations.map((projet) => (
          <div key={projet.id} className="column is-one-quarter mb-5">
            <div className="card">
              <Link to={`/realisations/${projet.id}`} className="card-image">
                <figure className="image is-4by3">
                  <img src={projet.photo_url} alt={projet.title} />
                </figure>
              </Link>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-5">{projet.title}</p>
                  </div>
                </div>
              </div>
              <footer className="card-footer">
                <Link
                  to={`/realisations/${projet.id}`}
                  className="card-footer-item"
                >
                  Détails
                </Link>
              </footer>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Realisations;

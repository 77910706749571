import React, { useState } from "react";
import {
  FaRulerCombined,
  FaCube,
  FaPencilRuler,
  FaSearch,
  FaBuilding,
  FaComments,
} from "react-icons/fa"; // Icônes pour les exemples
import { BsFillHouseCheckFill } from "react-icons/bs";
import { MdArchitecture } from "react-icons/md";

const prestations = [
  {
    icon: <FaRulerCombined />,
    title: "Etat des Lieux",
    description:
      "Evaluation détaillée de l'état actuel d'une propriété, habituellement réalisée avant de signer un bail ou d'entreprendre des travaux.",
    photo_url: "/img/Presta_06.webp",
    description2:
      "Cette évaluation exhaustive est essentielle pour garantir la protection des droits tant du locataire que du propriétaire. Elle constitue une référence fiable en cas de litige sur l'état du bien en début et fin de location.",
  },
  {
    icon: <FaPencilRuler />,
    title: "Avant-projet",
    description:
      "L'avant-projet détaille les plans préliminaires d'une maison, crucial pour la planification et l'approbation.",
    photo_url: "/img/Presta_07.webp",
    description2:
      "L'avant-projet est une phase cruciale de conception, où vos idées prennent forme à travers des plans préliminaires, essentiels pour la planification et l'approbation du projet. Cette étape inclut la création d'esquisses, plans d'étage, et élévations, tenant compte de vos besoins, souhaits, et des contraintes spécifiques du terrain. Ces documents sont fondamentaux pour obtenir les autorisations nécessaires, en assurant la conformité aux normes et réglementations locales. L'avant-projet vous permet également d'explorer différentes options de disposition et d'orientation des espaces, optimisant le confort, la fonctionnalité, et l'esthétique de votre future maison. Il sert de base pour les ajustements et améliorations avant de progresser vers les phases de conception et construction détaillées. Notre engagement est de vous accompagner dans l'affinement de ces plans jusqu'à votre entière satisfaction, garantissant ainsi la réalisation d'une maison qui répond parfaitement à vos attentes et aspirations.",
  },
  {
    icon: <BsFillHouseCheckFill />,
    title: "Permis de Construire",
    description:
      "Assistance dans les démarches administratives pour l'obtention des autorisations de construire.",
    photo_url: "/img/Presta_05.webp",
    description2:
      "Notre service d'assistance pour l'obtention du permis de construire vise à simplifier et accélérer les formalités administratives nécessaires à la réalisation de votre projet immobilier. Nous comprenons que naviguer à travers les procédures réglementaires peut être une tâche ardue pour les propriétaires et les promoteurs. C'est pourquoi nous offrons un accompagnement sur mesure pour préparer et soumettre votre dossier de permis de construire, en assurant sa conformité avec les réglementations locales en vigueur.Notre équipe expérimentée travaille en étroite collaboration avec les municipalités et autres autorités compétentes pour faciliter les échanges et répondre efficacement aux exigences spécifiques de chaque projet. De l'analyse préliminaire du site à la soumission finale du dossier, nous veillons à ce que chaque aspect soit méticuleusement pris en compte, réduisant ainsi les risques de refus ou de demandes de modification.En nous confiant cette mission, vous bénéficiez d'une tranquillité d'esprit, sachant que les aspects administratifs de votre projet sont gérés par des professionnels. Cela vous permet de vous concentrer pleinement sur les phases de conception et de construction, en rapprochant votre vision d'une réalité tangible.",
  },
  {
    icon: <MdArchitecture />,
    title: "Recours Architecte",
    description:
      "Besoin d'un architecte ? DR Conception vous met en relation avec son partenaire DPLG. ",
    photo_url: "/img/Presta_08.webp",
    description2:
      "Lorsque votre projet nécessite l'expertise d'un architecte, DR Conception est là pour faciliter cette collaboration essentielle. Nous comprenons l'importance de travailler avec un professionnel qualifié pour transformer vos idées en réalité. C'est pourquoi nous vous mettons en relation avec notre partenaire architecte DPLG, reconnu pour son excellence et son engagement envers la qualité. Notre architecte partenaire apporte une expertise technique approfondie, une créativité sans limites et une connaissance pointue des dernières tendances et réglementations en matière de construction et de design.Ce service exclusif assure que chaque aspect de votre projet est conçu avec précision, respecte les normes environnementales et s'intègre harmonieusement à son contexte. Que ce soit pour une nouvelle construction, une extension ou une rénovation, notre architecte DPLG travaille étroitement avec vous pour élaborer un projet qui non seulement répond à vos attentes mais les dépasse, en optimisant l'espace, la lumière et les matériaux.Faites le choix de l'excellence avec DR Conception et notre architecte partenaire pour faire de votre projet un chef-d'œuvre d'architecture.",
  },
  {
    icon: <FaCube />,
    title: "Visuels 3D",
    description:
      "Représentation en trois dimensions pour visualiser et concrétiser votre projet immobilier.",
    photo_url: "/img/Presta_03.webp",
    description2:
      "Nos visuels 3D offrent une immersion complète dans votre projet immobilier avant même le début des travaux. Cette technologie de pointe permet de donner vie à vos idées, en transformant les plans et les esquisses en images tridimensionnelles haute définition. Grâce à cette représentation précise, vous pouvez visualiser l'aspect final de votre projet, examiner chaque détail de l'aménagement intérieur et extérieur, et faire des ajustements nécessaires bien avant la construction.Cet outil révolutionnaire facilite la prise de décision et l'optimisation du design, assurant que le résultat final correspond parfaitement à vos attentes. Que ce soit pour une habitation individuelle, un bâtiment commercial ou un aménagement paysager, nos visuels 3D sont personnalisés pour refléter fidèlement votre vision.Embarquez dans un voyage visuel avec nos rendus 3D et vivez une expérience unique de prévisualisation de votre projet. DR Conception s'engage à utiliser les meilleures technologies pour vous accompagner dans la réalisation de vos rêves immobiliers.",
  },

  {
    icon: <FaSearch />,
    title: "Plan de Conception Générale",
    description:
      "Le plan de conception générale permet d’avoir une excellente compréhension et vision de votre projet. Aucun détail n’est négligé.",
    photo_url: "/img/Presta_04.webp",
    description2:
      "Notre service de Plan de Conception Générale est conçu pour offrir une vision complète et détaillée de votre projet immobilier, avant même le début de sa réalisation. Ce plan englobe tous les aspects cruciaux du projet, de l'aménagement des espaces à l'organisation fonctionnelle des zones, en passant par l'intégration paysagère et les solutions esthétiques. Grâce à une approche holistique, nous garantissons que chaque élément est pensé en harmonie avec l'ensemble, permettant une cohésion parfaite du projet.La création d'un Plan de Conception Générale est une étape fondamentale qui sert de socle à tout le processus de développement. Elle permet d'identifier les potentialités et contraintes du site, d'optimiser les ressources et de prévoir les solutions techniques adaptées. Cela vous assure non seulement de respecter les réglementations en vigueur mais également de maximiser le potentiel de votre investissement.Ce service est le point de départ pour créer des espaces qui répondent à vos aspirations tout en étant fonctionnels, durables et esthétiques. Avec DR Conception, plongez dans l'univers de la planification architecturale où chaque détail compte pour transformer votre vision en une réalité tangible et inspirante.",
  },
  {
    icon: <FaBuilding />,
    title: "Pour les Professionnels",
    description:
      "Nous accompagnons les professionnels dans la conception de leurs projets immobiliers.",
    photo_url: "/img/Presta_09.webp",
    description2:
      "Chez DR Conception, nous comprenons les défis uniques auxquels les professionnels sont confrontés lors de la conception de projets immobiliers. Que vous soyez un promoteur, un investisseur ou un entrepreneur, notre équipe offre un accompagnement sur mesure pour transformer vos visions ambitieuses en réalités tangibles. Nous combinons expertise technique, créativité et connaissance approfondie du marché pour vous guider à travers chaque étape du processus de conception, de la planification initiale à la livraison finale. En collaborant étroitement avec vous, nous nous assurons que vos projets non seulement répondent aux exigences réglementaires et environnementales, mais qu'ils se démarquent également par leur innovation et leur valeur ajoutée. Laissez DR Conception être le partenaire de choix pour mener à bien vos projets immobiliers professionnels, en dépassant les attentes et en maximisant le retour sur investissement.",
  },
  {
    icon: <FaComments />,
    title: "Conseils aux clients",
    description:
      "Nous vous conseillons dans le choix des matériaux, des couleurs, des équipements, des finitions, etc. pour votre projet immobilier.",
    photo_url: "/img/Presta_10.webp",
    description2:
      "DR Conception se démarque par son service de conseil personnalisé, guidant chaque client dans la sélection des matériaux, des couleurs, des équipements et des finitions pour leur projet immobilier. Notre expertise s'étend au-delà de la simple conception architecturale; nous vous accompagnons dans la création d'un espace qui reflète votre style personnel tout en répondant à des critères de fonctionnalité, de durabilité et d'efficacité énergétique. En collaborant avec des fournisseurs reconnus et en restant à l'affût des dernières tendances, nous vous aidons à faire des choix éclairés qui valorisent votre investissement sur le long terme. Qu'il s'agisse de définir l'ambiance d'une pièce, d'optimiser l'espace ou de sélectionner des équipements à la pointe de la technologie, DR Conception est votre partenaire pour concrétiser votre vision en respectant votre budget et vos aspirations. Faites confiance à notre équipe pour transformer votre projet immobilier en un lieu unique, alliant esthétique, confort et innovation.",
  },

  // ... autres prestations
];

function PrestationsHome() {
  const [selectedPrestation, setSelectedPrestation] = useState(null);

  const handlePrestationClick = (prestation) => {
    setSelectedPrestation(prestation);
  };

  const handleCloseModal = () => {
    setSelectedPrestation(null);
  };
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
          Un projet de construction ou d’extension ?
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          Voici quelques-unes des prestations que nous proposons pour vous
          accompagner dans votre projet.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {prestations.map((prestation, index) => (
            <div
              key={index}
              className="bg-blue-100 overflow-hidden shadow rounded-lg transition duration-300 ease-in-out hover:shadow-2xl transform hover:-translate-y-1 hover:bg-blue-200 cursor-pointer"
              onClick={() => handlePrestationClick(prestation)}
            >
              <div className="p-6">
                <div className="flex items-center justify-center text-gray-800 text-4xl mb-4">
                  {prestation.icon}
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {prestation.title}
                </h3>
                <p className="mt-2 text-base text-gray-500">
                  {prestation.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedPrestation && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
          onClick={handleCloseModal}
        >
          <div className="relative top-20 mx-auto shadow-lg rounded-md bg-white max-w-md">
            <div className="flex justify-end p-2">
              <button
                onClick={handleCloseModal}
                className="text-gray-600 hover:text-gray-900"
              >
                <span className="text-2xl">&times;</span>
              </button>
            </div>
            <div className="p-4">
              <img
                src={selectedPrestation.photo_url}
                alt={selectedPrestation.title}
                className="w-full"
              />
              <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                {selectedPrestation.title}
              </h3>
              <p className="mt-2 text-base text-gray-500">
                {selectedPrestation.description2}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrestationsHome;

// components/TuilesPrestations.js
import React from "react";

const TuilesPrestations = () => {
  // Liste des images (remplacez par vos propres images)
  const images = [
    {
      src: "/img/Presta_04.webp",
      alt: "Plans 2D",
      className: "h-64 w-full object-cover",
    },
    {
      src: "/img/Presta_03.webp",
      alt: "Visuels 3D",
      className: "h-64 w-full object-cover",
    },
    {
      src: "/img/Presta_02.webp",
      alt: "Etude PLU",
      className: "h-64 w-full object-cover",
    },
    {
      src: "/img/Presta_05.webp",
      alt: "Permis de Construire",
      className: "h-64 w-full object-cover",
    },
    {
      src: "/img/Presta_01.webp",
      alt: "Aménagement",
      className: "h-64 w-full object-cover",
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
      {images.map((image, index) => (
        <div
          key={index}
          className="relative flex justify-center items-center overflow-hidden group"
        >
          <img src={image.src} alt={image.alt} className={image.className} />
          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <span className="text-white text-xl font-semibold">
              {image.alt}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TuilesPrestations;

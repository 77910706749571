import React, { useState } from "react";
import TuilesPrestations from "./TuilesPrestations";
import AsidePrestations from "./AsidePrestations";
import TarifsPrestations from "./TarifsPrestations";

const Prestations = () => {
   const [categorieSelected, setCategorieSelected] = useState(
     "Toutes les prestations"
   );
  const handleCategoryChange = (category) => {
    setCategorieSelected(category);
  };
  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold text-center my-8">Nos Prestations</h1>
        <TuilesPrestations />
      </div>
      <div className="container mx-auto p-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/4 px-4">
            <AsidePrestations onCategoryChange={handleCategoryChange} />
          </div>
          <div className="bg-gray-100 p-3 w-full lg:w-3/4 px-4">
            <TarifsPrestations selectedCategorie={categorieSelected} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prestations;

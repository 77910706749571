import React, { useEffect, useState } from "react";

const LogoBanner = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Déclencher l'effet dès que le composant est monté
    setIsLoaded(true);

    // Fonction pour vérifier si le mode sombre est activé
    const checkDarkMode = () => {
      setIsDarkMode(document.documentElement.classList.contains("dark"));
    };

    // Vérifier immédiatement si le mode sombre est activé au montage
    checkDarkMode();

    // Écouter les changements sur le thème pour ajuster l'image du logo
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          checkDarkMode();
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
    });

    // Nettoyer l'observateur à la désinscription du composant
    return () => observer.disconnect();
  }, []);

  return (
    <div
      className={`transition-opacity duration-1000 ${
        isLoaded ? "opacity-100" : "opacity-0"
      } flex justify-center items-center my-12`}
    >
      <img
        src={isDarkMode ? "/img/logo_drconcept33_black.png" : "/img/logo_drconcept33_white.png"}
        alt="Logo"
        className="h-96 w-auto mt-10"
      />
      
      {/* Ajuste la hauteur selon tes besoins */}
    </div>
  );
};

export default LogoBanner;

import React, { useState, useEffect } from "react";
import axios from "axios";
import CardPrestations from "./CardPrestations";

const TarifsPrestations = ({ selectedCategorie }) => {
  const [prestations, setPrestations] = useState([]);

  useEffect(() => {
    const fetchPrestations = async () => {
      try {
        const response = await axios.get(
          "https://api.dr-concept.fr/getAllPrestations"
        );
        setPrestations(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des prestations:", error);
      }
    };

    fetchPrestations();
  }, []);

  const filteredPrestations =
    selectedCategorie === "Toutes les prestations"
      ? prestations
      : prestations.filter(
          (prestation) => prestation.category === selectedCategorie
        );

  return (
    <div className="space-y-4 rounded-lg">
      {filteredPrestations.map((prestation, index) => (
        <CardPrestations
          key={index}
          photo_url={prestation.photo_url}
          titre={prestation.titre}
          description={prestation.description}
          tarif={prestation.tarif} // Assure-toi que le champ tarif existe dans ta réponse d'API ou ajuste-le en conséquence.
        />
      ))}
    </div>
  );
};

export default TarifsPrestations;

import React from "react";
import HeaderCarousel from "./HeaderCarousel";
import PrestationsHome from "./PresentationsHome";
import RealisationHome from "./RealisationHome";
import LogoBanner from "./LogoBanner";
import Avis from "./AvisHome";
import AboutSection from "./AboutHome";
import PartenairesHome from "./PartenairesHome ";

// Autres composants que tu pourrais vouloir inclure
// import AutreComposant from './AutreComposant';

function Home() {
  return (
    <div>
      <LogoBanner />
      <HeaderCarousel />
      <section className="py-8 px-4">
        <PrestationsHome />
        <AboutSection />
        <RealisationHome />
        <Avis />
        <PartenairesHome />
        {/* Ici, tu peux continuer à construire le reste de ta page d'accueil */}
        {/* <AutreComposant /> */}
      </section>
      {/* Plus de sections ou de composants peuvent être ajoutés ici */}
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

function RealisationHome() {
  const [realisations, setRealisations] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchRealisations = async () => {
      try {
        const response = await axios.get(
          "https://api.dr-concept.fr/getAllRealisations"
        );
        const visibleRealisations = response.data.filter(
          (realisation) => realisation.visible === true
        );
        setRealisations(visibleRealisations);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des réalisations:",
          error
        );
      }
    };

    fetchRealisations();
  }, []);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? realisations.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === realisations.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  if (realisations.length === 0) {
    return <div>Chargement des réalisations...</div>;
  }

  return (
    <div className="bg-white py-12">
      <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
        Nos Réalisations
      </h2>
      <p className="text-lg text-gray-600 mb-6">
        Voici quelques-unes de nos réalisations.
      </p>
      <div className="relative w-full flex items-center">
        <button onClick={goToPrevious} className="absolute left-0 z-10 ml-4">
          <FaArrowAltCircleLeft className="text-black text-2xl" />
        </button>

        <div className="w-full flex justify-center items-center">
          <img
            src={realisations[currentIndex].photo_url}
            alt={realisations[currentIndex].title}
            className="w-full max-w-[1024px] max-h-[600px] object-cover rounded-lg shadow-lg"
          />
          <div className="absolute bottom-0 bg-black bg-opacity-50 w-full max-w-[1024px] text-white p-4">
            <h3 className="text-l font-bold">
              {realisations[currentIndex].title}
            </h3>
            <p>{realisations[currentIndex].description}</p>
          </div>
        </div>

        <button onClick={goToNext} className="absolute right-0 z-10 mr-4">
          <FaArrowAltCircleRight className="text-black text-2xl" />
        </button>
      </div>
    </div>
  );
}

export default RealisationHome;

import React, { useState } from "react";
import ContactsTab from "./ContactsTab";
import CarouselTab from "./CarouselTab";
import PrestationsTab from "./PrestationsTab";
import RealisationsTab from "./RealisationsTab";
import "bulma/css/bulma.min.css";

function AdminSettings() {
  const [activeTab, setActiveTab] = useState("ContactsTab");

  const renderTabContent = () => {
    switch (activeTab) {
      case "ContactsTab":
        return <ContactsTab />;
      case "CarouselTab":
        return <CarouselTab />;
      case "PrestationsTab":
        return <PrestationsTab />;
      case "RealisationsTab":
        return <RealisationsTab />;
      default:
        return <div>Sélectionnez un onglet</div>;
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <div className="tabs is-toggle is-fullwidth">
        <ul>
          <li
            className={activeTab === "ContactsTab" ? "is-active" : ""}
            onClick={() => setActiveTab("ContactsTab")}
          >
            <a>Contacts</a>
          </li>
          <li
            className={activeTab === "CarouselTab" ? "is-active" : ""}
            onClick={() => setActiveTab("CarouselTab")}
          >
            <a>Carousel</a>
          </li>
          <li
            className={activeTab === "PrestationsTab" ? "is-active" : ""}
            onClick={() => setActiveTab("PrestationsTab")}
          >
            <a>Prestations</a>
          </li>
          <li
            className={activeTab === "RealisationsTab" ? "is-active" : ""}
            onClick={() => setActiveTab("RealisationsTab")}
          >
            <a>Réalisations</a>
          </li>
        </ul>
      </div>

      <div className="tab-content">{renderTabContent()}</div>
    </div>
  );
}

export default AdminSettings;

import React, { useState } from "react";
import { FaStar, FaRegStar } from "react-icons/fa"; // Importe les icônes d'étoiles
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const avis = [
  {
    nom: "François D.",
    date: "12 Février 2024",
    commentaire:
      "Une expérience incroyable, très professionnel et à l'écoute. Je recommande vivement!",
    etoiles: 5,
  },
  {
    nom: "Marc F.",
    date: "25 Février 2024",
    commentaire:
      "Service exceptionnel, travail rapide et de qualité. Très satisfait du résultat.",
    etoiles: 4,
  },
  {
    nom: "Sophie T.",
    date: "1 Mars 2024",
    commentaire:
      "Grand professionnalisme et un rendu au-delà de mes attentes. Un grand merci!",
    etoiles: 5,
  },
  {
    nom: "Julie R.",
    date: "10 Février 2024",
    commentaire:
      "Le projet a été réalisé avec beaucoup d'attention et de créativité. L'équipe était à l'écoute de nos besoins. Nous sommes ravis !",
    etoiles: 5,
  },
  {
    nom: "Antoine B.",
    date: "15 Janvier 2024",
    commentaire:
      "Communication excellente et projet mené avec professionnalisme. Je suis impressionné par le souci du détail.",
    etoiles: 4,
  },
  {
    nom: "Clara M.",
    date: "20 Décembre 2023",
    commentaire:
      "Des conseils avisés et un accompagnement personnalisé du début à la fin. Un vrai partenaire pour notre projet !",
    etoiles: 5,
  },
  {
    nom: "Lucas P.",
    date: "5 Novembre 2023",
    commentaire:
      "Une équipe dynamique et innovante. Le résultat dépasse toutes nos espérances. Un grand merci pour votre travail !",
    etoiles: 5,
  },
  {
    nom: "Émilie S.",
    date: "25 Octobre 2023",
    commentaire:
      "Professionnels, réactifs et passionnés. Leur expertise a fait toute la différence pour notre projet.",
    etoiles: 4,
  },
];

function Avis() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [avisPost, setAvisPost] = useState({
    nomPrenom: "",
    contenu: "",
    etoiles: 0,
  });

  const handleStarClick = (index) => {
    setAvisPost({ ...avisPost, etoiles: index + 1 }); // Assurez-vous que cette ligne est correcte
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construction du corps du formulaire à envoyer
    const formData = new FormData();
    formData.append("Nom-Prénom", avisPost.nomPrenom);
    formData.append("Contenu du message", avisPost.contenu);
    formData.append("Nombre d'étoiles", avisPost.etoiles);

    // Envoi des données du formulaire à Formspree
    try {
      const response = await fetch("https://formspree.io/f/mleqlrwn", {
        // Remplacez {votreID} par votre endpoint Formspree
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        // Gérez ici la réponse réussie (par exemple, afficher un message de succès)
        console.log("Formulaire envoyé avec succès !");
        setIsModalOpen(false); // Fermer la modale
        // Réinitialiser l'état du formulaire pour vider les champs
        setAvisPost({
          nomPrenom: "",
          contenu: "",
          etoiles: 0,
        });
      } else {
        // Gérez ici les erreurs potentielles
        console.error("Erreur lors de l'envoi du formulaire");
      }
    } catch (error) {
      console.error("Exception lors de l'envoi du formulaire", error);
    }
  };

  // Modification pour le rendu des étoiles
  const renderEtoiles = (nombreEtoiles) => {
    let etoiles = [];
    for (let i = 0; i < 5; i++) {
      etoiles.push(
        i < nombreEtoiles ? (
          <FaStar
            key={i}
            onClick={() => handleStarClick(i)}
            className="text-yellow-400"
            style={{ cursor: "pointer", display: "inline" }}
          />
        ) : (
          <FaRegStar
            key={i}
            onClick={() => handleStarClick(i)}
            className="text-gray-300"
            style={{ cursor: "pointer", display: "inline" }}
          />
        )
      );
    }
    return <div style={{ display: "flex" }}>{etoiles}</div>; // Assure l'alignement horizontal
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768, // Ajuste pour les écrans plus petits
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-gray-100 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Ils nous font confiance
        </h2>
        <Slider {...settings}>
          {avis.map((temoignage, index) => (
            <div key={index} className="px-4 mb-6">
              <div className="bg-white rounded-lg shadow p-4 h-full flex flex-col justify-between">
                <div className="text-gray-800 text-sm mb-2">
                  <div className="flex items-center mb-1">
                    {renderEtoiles(temoignage.etoiles)}
                  </div>
                  <p className="italic">"{temoignage.commentaire}"</p>
                </div>
                <div>
                  <p className="text-gray-600 font-bold">{temoignage.nom}</p>
                  <p className="text-gray-500 text-xs">{temoignage.date}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-center mt-6">
          {" "}
          {/* Centre le bouton sous le slider */}
          <button
            onClick={() => setIsModalOpen(true)}
            className="button is-info"
          >
            Ajouter un Avis
          </button>
        </div>
        <div className={`modal ${isModalOpen ? "is-active" : ""}`}>
          <div
            className="modal-background"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Ajouter un Avis</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setIsModalOpen(false)}
              ></button>
            </header>
            <section className="modal-card-body">
              <form
                action="https://formspree.io/f/myyrjrvl"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="field">
                  <label className="label">Nom-Prénom</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Votre nom"
                      value={avisPost.nomPrenom}
                      onChange={(e) =>
                        setAvisPost({ ...avis, nomPrenom: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Contenu du message</label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="Votre avis"
                      value={avisPost.contenu}
                      onChange={(e) =>
                        setAvisPost({ ...avisPost, contenu: e.target.value })
                      }
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Nombre d'étoiles</label>
                  <div className="control">
                    <div className="select">
                      <select
                        value={avisPost.etoiles}
                        onChange={(e) =>
                          setAvisPost({ ...avisPost, etoiles: e.target.value })
                        }
                        required
                      >
                        <option value="0">
                          Sélectionnez le nombre d'étoiles
                        </option>
                        <option value="1">⭐</option>
                        <option value="2">⭐⭐</option>
                        <option value="3">⭐⭐⭐</option>
                        <option value="4">⭐⭐⭐⭐</option>
                        <option value="5">⭐⭐⭐⭐⭐</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success">
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Avis;

import React from "react";
import "bulma/css/bulma.min.css";
import "./Home.css";

const AboutSection = () => {
  return (
    <section className="section about-section">
      <div className="container">
        <div className="columns is-multiline about-content">
          <div className="column is-12-mobile is-6-desktop">
            {" "}
            {/* Ajustement pour le responsive */}
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
              À propos
            </h2>{" "}
            {/* Style ajusté du titre */}
            <p>
              J'ai débuté ma carrière professionnelle en 2002, à l'âge de 15
              ans, en tant que menuisier chez les <b>"Compagnons du Devoir"</b>{" "}
              à Périgueux (24). Fort de plus de dix années d'expérience dans la
              menuiserie, j'ai décidé de poursuivre mes études dans le domaine
              de <b>l'architecture</b> au sein de la même organisation, mais
              cette fois à Floirac (33).
            </p>
            <br />
            <p>
              Après onze ans passés au sein d'un <b>bureau d'études</b> dans un
              cabinet d'architecture et de maîtrise d'œuvre, où j'occupais le
              rôle de responsable et référent, j'ai pris la décision de fonder{" "}
              <b>"Dr Concept"</b> en 2021, implanté dans l'Entre-deux-Mers en
              Gironde.
            </p>
            <br />
            <p>
              Chaque jour, je continue d'être inspiré par les projets sur
              lesquels je travaille. Dans chacune de mes réalisations, je
              m'efforce de trouver l'équilibre parfait entre confort et
              élégance. Mon but dépasse la simple création de structures
              esthétiques ; il s'agit véritablement d'enrichir le quotidien.
              Animé par une passion pour les espaces et les individus qui les
              occupent, je conçois tous mes projets avec un souci constant de
              fonctionnalité.
            </p>
          </div>
          <div className="column is-12-mobile is-6-desktop">
            {" "}
            {/* Ajustement pour le responsive */}
            <figure className="about-image">
              <img
                src="/img/0_0.webp"
                alt="Dr.Concept33 - Atelier de dessin en Architecture"
                style={{ maxHeight: "66.67%", objectFit: "cover" }}
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;

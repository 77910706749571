import React from "react";
import ContactHome from "../Home/ContactHome"; // Assurez-vous que ce composant est votre formulaire
import './ContactPage.css'; // Assurez-vous que ce fichier est bien présent

const ContactPage = () => {
  return (
    <div className="container mt-6 mt100">
      {" "}
      {/* Ajout du marginTop pour éviter la superposition avec la Navbar */}
      <div className="columns  px-3 is-multiline is-variable is-8-desktop">
        <div className="column is-6-desktop is-12-mobile">
          <h1 className="title">Contact</h1>
          <p className="contact-page-text">
            Bienvenue sur notre page de contact <b>Dr. Concept 33</b> ! Que vous
            soyez un professionnel ou un particulier, notre entreprise, située
            dans le Créonnais, est prête à collaborer avec vous sur votre
            prochain projet. Nous avons l'expérience de travailler en étroite
            collaboration avec des architectes et des maîtres d'œuvre pour
            offrir des solutions adaptées à vos besoins.
          </p>
          <br />
          <p className="contact-page-text">
            <strong>Services locaux et à distance :</strong> Nous nous déplaçons
            dans toute la Gironde pour des rencontres et des évaluations sur
            site. De plus, nous proposons des prestations à distance pour nos
            clients partout en France, garantissant ainsi une flexibilité
            maximale pour votre projet.
          </p>
          <br />
          <br />
          <h2 className="contact-page-text">
            <b>Nous contacter :</b>
          </h2>
          <p className="contact-page-text">
            Pour toute question ou pour discuter de vos idées, n'hésitez pas à
            nous contacter. Remplissez le formulaire ou utilisez les coordonnées
            suivantes pour un échange direct :
          </p>
          <ul>
            <li className="contact-page-text">
              Téléphone : <a href="tel:+33666150748">06.66.15.07.48</a>
            </li>
          </ul>
          <br />
          <p className="contact-page-text">
            Nous sommes impatients d'entendre parler de votre projet et de voir
            comment nous pouvons vous aider à le réaliser.
          </p>
        </div>
        <div className="column is-6-desktop is-12-mobile">
          {/* Assurez-vous que ContactHome est votre formulaire */}
          <ContactHome />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;

import React, { useState } from "react";
import { FaPaperPlane } from "react-icons/fa";

function ContactHome() {
  const [showNotification, setShowNotification] = useState(false);

  const [contact, setContact] = useState({
    nom: "",
    prenom: "",
    telephone: "",
    mail: "",
    demande: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setContact({ ...contact, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Préparation des données pour l'API de votre base de données
    const data = {
      nom: contact.nom,
      prenom: contact.prenom,
      telephone: contact.telephone,
      mail: contact.mail, // Assurez-vous que le nom du champ correspond à votre modèle Sequelize
      demande: contact.demande,
    };

    // Préparation des données pour Formspree
    const formData = new FormData();
    formData.append("Nom", contact.nom);
    formData.append("Prénom", contact.prenom);
    formData.append("Téléphone", contact.telephone);
    formData.append("Email", contact.mail);
    formData.append("Message", contact.demande);

    try {
      // Appel à votre API
      const apiResponse = await fetch(
        "https://api.dr-concept.fr/createContactForm",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      // Gestion de la réponse de votre API
      if (apiResponse.ok) {
        console.log("Données envoyées à la base de données avec succès !");
        // Gestion de l'état et de la notification ici si nécessaire
      } else {
        console.error(
          "Erreur lors de l'envoi des données à la base de données."
        );
      }

      // Appel à Formspree
      const formSpreeResponse = await fetch("https://formspree.io/f/meqyzjlr", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      // Gestion de la réponse de Formspree
      if (formSpreeResponse.ok) {
        console.log("Formulaire envoyé à Formspree avec succès !");
        // Réinitialisation de l'état du formulaire et affichage de la notification
        setContact({
          nom: "",
          prenom: "",
          telephone: "",
          mail: "",
          demande: "",
        });
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000);
      } else {
        console.error("Erreur lors de l'envoi du formulaire à Formspree.");
      }
    } catch (error) {
      // Gestion des exceptions pour les appels d'API
      console.error("Exception lors de l'envoi des données", error);
    }
  };

  return (
    <section className="bg-blue-100 py-8 px-4">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-lg">
        <div className="md:flex">
          <div className="w-full p-4">
            <div className="mb-4">
              <h3 className="font-semibold text-lg tracking-wide">
                Formulaire de contact
              </h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm text-gray-600" htmlFor="nom">
                  Nom
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Nom"
                  id="nom"
                  value={contact.nom}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-600" htmlFor="prenom">
                  Prénom
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Prénom"
                  id="prenom"
                  value={contact.prenom}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm text-gray-600"
                  htmlFor="telephone"
                >
                  Téléphone
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="tel"
                  placeholder="Téléphone"
                  id="telephone"
                  value={contact.telephone}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-gray-600" htmlFor="email">
                  Email
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder="Email"
                  id="mail"
                  value={contact.mail}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm text-gray-600"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="w-full h-20 px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  placeholder="Votre message"
                  id="demande"
                  value={contact.demande}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="mb-4">
                <button
                  className="w-full flex justify-center items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Envoyer <FaPaperPlane className="ml-2" />
                </button>
                {showNotification && (
                  <p className="text-green-500 text-sm mt-2">
                    Votre message a été envoyé avec succès !
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactHome;

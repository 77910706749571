import React from "react";

const CardPrestations = ({ photo_url, titre, description, tarif }) => {
  return (
    <div className="flex flex-col md:flex-row items-start bg-white border border-gray-200 rounded-lg shadow w-full hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
      <img
        className="object-cover w-full rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        src={photo_url}
        alt={titre}
      />
      <div className="p-4 w-full">
        <h5 className="text-left text-1xl font-bold tracking-tight text-gray-900 dark:text-white">
          {titre}
        </h5>
        <p className="text-left mb-3 font-normal text-gray-700 dark:text-gray-400">
          {description}
        </p>
        <p className="text-left text-lg font-bold text-red-600 dark:text-red-400">
          {tarif}
        </p>
      </div>
    </div>
  );
};

export default CardPrestations;
